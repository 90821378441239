<template>
  <div class="hello">
    <el-container>
      <el-aside class="leftbar">
        <el-menu
          class="nav"
          background-color="#0d2945"
          text-color="#fff"
          default-active="2"
          :collapse="isCollapse"
        >
          <span href="/" class="logo" v-if="!isCollapse"
            >TokTok Admin v.1.5</span
          >
          <span href="/" class="logo" v-else>v.1.5</span>
          <router-link to="/dashboardReport">
            <el-menu-item
              index="8"
              style="margintop: 20%"
              v-if="
                !mArrayIncludes(role, [
                  'driverManager',
                  'hr',
                  'driverShow',
                  'menu_editor',
                  'ops',
                  'part-timer'
                ])
              "
            >
              <i class="el-icon-s-data"></i>
              <span slot="title">Report Dashboard</span>
            </el-menu-item>
          </router-link>
          <router-link to="/dashboard">
            <el-menu-item
              index="1"
              style="margintop: 20%"
              v-if="
                !mArrayIncludes(role, [
                  'driverManager',
                  'hr',
                  'driverShow',
                  'menu_editor',
                  'ops',
                  'part-timer'
                ])
              "
            >
              <i class="el-icon-s-data"></i>
              <span slot="title">Client Dashboard</span>
            </el-menu-item>
          </router-link>
          <el-submenu
            v-if="
              !mArrayIncludes(role, [
                'driverManager',
                'hr',
                'driverShow',
                'part-timer'
              ])
            "
            index="2"
          >
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title">Харилцагчийн удирдлага</span>
            </template>
            <el-menu-item-group>
              <router-link to="/main">
                <el-menu-item index="2-1">
                  Харилцагчийн удирдлага
                </el-menu-item>
              </router-link>
              <router-link
                to="/newPartners"
                v-if="!mArrayIncludes(role, ['ops'])"
              >
                <el-menu-item index="2-3">
                  Шинэ харилцагчийн хүсэлт
                </el-menu-item>
              </router-link>
              <router-link
                to="/noSalesPartner"
                v-if="!mArrayIncludes(role, ['ops'])"
              >
                <el-menu-item index="2-4">
                  Автомат цуцлалт
                </el-menu-item>
              </router-link>
              <router-link to="/cognitoClientList">
                <el-menu-item
                  index="2-5"
                  v-if="mArrayIncludes(role, ['boss', 'admin'])"
                >
                  Client нэвтрэх эрх
                </el-menu-item>
              </router-link>
              <router-link to="/cognitoCcList">
                <el-menu-item
                  index="2-6"
                  v-if="mArrayIncludes(role, ['supervisor'])"
                >
                  CC нэвтрэх эрх
                </el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu
            v-if="
              mArrayIncludes(role, [
                'boss',
                'ops',
                'TL',
                'CC',
                'driverManager',
                'hr',
                'driverShow'
              ])
            "
            index="3"
          >
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span slot="title">Жолоочийн удирдлага</span>
            </template>
            <el-menu-item-group>
              <router-link to="/driver-list">
                <el-menu-item index="3-1">Жолоочийн жагсаалт</el-menu-item>
              </router-link>
              <router-link to="/driver-team-list">
                <el-menu-item
                  index="3-2"
                  v-if="mArrayIncludes(role, ['boss', 'ops', 'driverManager'])"
                  >Жолоочийн баг</el-menu-item
                >
              </router-link>
              <router-link
                to="/driverTimeSchedule"
                v-if="!mArrayIncludes(role, ['driverShow'])"
              >
                <el-menu-item index="3-3">Жолоочийн цаг</el-menu-item>
              </router-link>
              <router-link to="/driver-zone">
                <el-menu-item
                  index="3-4"
                  v-if="mArrayIncludes(role, ['boss', 'hr'])"
                  >Жолоочийн хүргэлтийн бүс</el-menu-item
                >
              </router-link>
              <router-link to="/suggest-list">
                <el-menu-item
                  index="3-5"
                  v-if="!mArrayIncludes(role, ['driverShow', 'ops'])"
                  >Санал болгох тохиргоонууд
                </el-menu-item>
              </router-link>
              <router-link to="/driver-payment">
                <el-menu-item
                  index="3-6"
                  v-if="!mArrayIncludes(role, ['boss', 'hr', 'ops'])"
                  >Жолоочийн хүргэлтийн төлбөр</el-menu-item
                >
              </router-link>
              <router-link to="/tpr-delivery-price">
                <el-menu-item
                  index="3-7"
                  v-if="mArrayIncludes(role, ['boss', 'hr', 'ops'])"
                  >TPR хүргэлтийн төлбөр</el-menu-item
                >
              </router-link>
              <router-link to="/incentives">
                <el-menu-item
                  index="3-8"
                  v-if="mArrayIncludes(role, ['boss', 'hr'])"
                  >Бүсийн урамшуулал тохиргоо</el-menu-item
                >
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <!-- v-if="
            !mArrayIncludes(role, [
              'driverManager',
              'hr',
              'driverShow',
              'menu_editor'
            ])
          " -->
          <router-link
            to="/late-pay-step"
            v-if="!mArrayIncludes(role, ['ops', 'part-timer'])"
          >
            <el-menu-item index="20">
              <i class="el-icon-s-data"></i>
              <span slot="title">Одоо аваад дараа төл</span>
            </el-menu-item>
          </router-link>

          <el-submenu
            index="4"
            v-if="
              !mArrayIncludes(role, [
                'driverManager',
                'driverShow',
                'part-timer'
              ])
            "
          >
            <template slot="title">
              <i class="el-icon-mobile"></i>
              <span slot="title">Апп удирдлага</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/collection-fee-config"
                v-if="mArrayIncludes(role, ['boss', 'admin'])"
              >
                <el-menu-item index="1-21"
                  >Цуглуулалтын төлбөрийн тохиргоо</el-menu-item
                >
              </router-link>
              <router-link
                to="/suggest-menu"
                v-if="
                  mArrayIncludes(role, [
                    'boss',
                    'ST',
                    'SM',
                    'admin',
                    'client_care'
                  ])
                "
              >
                <el-menu-item index="1-8">Санал болгох меню</el-menu-item>
              </router-link>
              <router-link
                to="/timeLimit-banner"
                v-if="
                  mArrayIncludes(role, [
                    'boss',
                    'ST',
                    'SM',
                    'admin',
                    'client_care'
                  ])
                "
              >
                <el-menu-item index="1-13"
                  >Сурталчилгаанд цаг тохируулах</el-menu-item
                >
              </router-link>
              <router-link
                to="/timeLimit-menu"
                v-if="
                  mArrayIncludes(role, [
                    'boss',
                    'ST',
                    'SM',
                    'admin',
                    'client_care'
                  ])
                "
              >
                <el-menu-item index="1-9"
                  >Бүтээгдэхүүнд цаг тохируулах</el-menu-item
                >
              </router-link>
              <router-link
                to="/banner"
                v-if="
                  !mArrayIncludes(role, [
                    'ops',
                    'TL',
                    'CC',
                    'finance',
                    'menu_editor'
                  ])
                "
              >
                <el-menu-item index="1-1">Суртчилгаа</el-menu-item>
              </router-link>
              <router-link
                to="/service"
                v-if="
                  !mArrayIncludes(role, [
                    'ops',
                    'TL',
                    'CC',
                    'finance',
                    'hr',
                    'menu_editor'
                  ])
                "
              >
                <el-menu-item index="1-2">Үйлчилгээ</el-menu-item>
              </router-link>
              <router-link
                to="/categories"
                v-if="
                  !mArrayIncludes(role, [
                    'ops',
                    'TL',
                    'CC',
                    'finance',
                    'hr',
                    'menu_editor'
                  ])
                "
              >
                <el-menu-item index="1-3">Үндсэн ангилал</el-menu-item>
              </router-link>
              <router-link
                to="/menuCategories"
                v-if="
                  !mArrayIncludes(role, ['ops', 'TL', 'CC', 'finance', 'hr'])
                "
              >
                <el-menu-item index="1-5">Дотоод ангилал</el-menu-item>
              </router-link>
              <router-link
                to="/payment"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-4">Төлбөрийн хэрэгсэл</el-menu-item>
              </router-link>
              <router-link
                to="/user-payment"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-10">Хэрэглэгчийн төлбөр</el-menu-item>
              </router-link>
              <router-link
                to="/pick-hour"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-11"
                  >Ачаалалтай цагийн төлбөр</el-menu-item
                >
              </router-link>
              <router-link
                to="/badge"
                v-if="
                  mArrayIncludes(role, [
                    'boss',
                    'ST',
                    'SM',
                    'admin',
                    'client_care'
                  ])
                "
              >
                <el-menu-item index="1-6">Бадже</el-menu-item>
              </router-link>
              <router-link
                to="/special"
                v-if="mArrayIncludes(role, ['boss', 'ST', 'SM', 'admin'])"
              >
                <el-menu-item index="1-7">Онцлох бүтээгдэхүүн</el-menu-item>
              </router-link>
              <router-link
                to="/sortTypes"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-14">
                  Үндсэн ангилалын эрэмбэ
                </el-menu-item>
              </router-link>
              <router-link
                to="/desired-delivery"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-15">
                  Хүслийн хүргэлт
                </el-menu-item>
              </router-link>
              <router-link
                to="/tpr-config"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor'])"
              >
                <el-menu-item index="1-16">
                  TPR хязгаарлалт тохиргоо
                </el-menu-item>
              </router-link>
              <router-link
                to="/story"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-17">Story</el-menu-item>
              </router-link>
              <router-link
                to="/Marketing"
                v-if="!mArrayIncludes(role, ['hr', 'menu_editor', 'ops'])"
              >
                <el-menu-item index="1-18">Marketing</el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu
            index="5"
            v-if="
              !mArrayIncludes(role, [
                'driverManager',
                'hr',
                'driverShow',
                'menu_editor'
              ])
            "
          >
            <template slot="title">
              <i class="el-icon-location"></i>
              <span slot="title">Хаяг удирдлага</span>
            </template>
            <el-menu-item-group>
              <router-link
                to="/list-address"
                v-if="!mArrayIncludes(role, ['finance', 'other'])"
              >
                <el-menu-item index="4-1">Хаяг засах</el-menu-item>
              </router-link>
              <router-link
                to="/address"
                v-if="!mArrayIncludes(role, ['finance', 'other'])"
              >
                <el-menu-item index="4-2">Байршил засах</el-menu-item>
              </router-link>
              <router-link
                to="/search"
                v-if="!mArrayIncludes(role, ['finance', 'other'])"
              >
                <el-menu-item index="4-3">Байршилаар хайх</el-menu-item>
              </router-link>
              <router-link
                to="/show-polygon-and-location"
                v-if="!mArrayIncludes(role, ['part-timer'])"
              >
                <el-menu-item index="4-4">Харилцагчдийн байршил</el-menu-item>
              </router-link>
              <router-link
                to="/address-list"
                v-if="!mArrayIncludes(role, ['part-timer'])"
              >
                <el-menu-item index="4-5">Хаягийн жагсаалт</el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu
            index="6"
            v-if="
              !mArrayIncludes(role, [
                'driverManager',
                'hr',
                'driverShow',
                'menu_editor',
                'ops',
                'part-timer'
              ])
            "
          >
            <template slot="title">
              <i class="el-icon-s-promotion"></i>
              <span slot="title">Notification</span>
            </template>
            <el-menu-item-group style="background-color: #0d2945">
              <router-link to="/createNotification">
                <el-menu-item index="3-1" style="color: white">
                  Create
                </el-menu-item>
              </router-link>
              <router-link to="/notificationList">
                <el-menu-item index="2-2" style="color: white">
                  List
                </el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu
            index="7"
            v-if="
              mArrayIncludes(role, [
                'ST',
                'SM',
                'admin',
                'client_care',
                'other',
                'boss'
              ])
            "
          >
            <template slot="title">
              <i class="el-icon-dish-1"></i>
              <span slot="title"> Kitchen</span>
            </template>
            <el-menu-item-group>
              <router-link to="/kitchenBanner">
                <el-menu-item index="4-1">Суртчилгаа</el-menu-item>
              </router-link>
              <router-link to="/timeLimit-Kitchenbanner">
                <el-menu-item index="4-2">Kitchen цаг тохируулах</el-menu-item>
              </router-link>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container :class="!isCollapse ? 'main-container' : 'main-container1'">
        <el-header>
          <el-row>
            <el-col :span="1">
              <i
                v-if="!isCollapse"
                class="el-icon-s-fold"
                style="font-size: 30px; margin-top: 20%"
                @click="isCollapse = !isCollapse"
              ></i>
              <i
                v-else
                class="el-icon-s-unfold"
                style="font-size: 30px; margin-top: 20%"
                @click="isCollapse = !isCollapse"
              ></i>
            </el-col>
            <el-col :span="23" style="margin-top: 1.2%">
              <b style="marginleft: 2%"> {{ userName }}</b>
            </el-col>
          </el-row>
          <el-button
            type="text"
            class="logout"
            icon="el-icon-s-custom"
            @click="logout"
            >Гарах</el-button
          >
        </el-header>
        <el-main>
          <router-view
            v-if="
              mArrayIncludes(role, [
                'admin',
                'boss',
                'supervisor',
                'employee',
                'marketing',
                'ops',
                'menu_editor',
                'address_editor',
                'ST',
                'SM',
                'TL',
                'CC',
                'client_care',
                'finance',
                'other',
                'driverManager',
                'hr',
                'driverShow',
                'part-timer'
              ])
            "
          ></router-view>
          <div v-else>Таны эрх хүрэхгүй байна.</div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import {
  signOut,
  getGroups,
  getUserName,
  checkCurrentUserExist
} from "../utils/auth";
import { arrayIncludes } from "../helpers/helper";
export default {
  data() {
    return {
      userName: "",
      role: "",
      isCollapse: false
    };
  },
  created() {
    const user = checkCurrentUserExist();
    if (user) checkCurrentUserExist();
    getUserName().then(name => {
      this.userName = name.attributes.email.split("@")[0];
    });
    getGroups().then(data => {
      this.role = data;
    });
  },
  methods: {
    sideBars() {
      this.isCollapse != this.isCollapse;
    },
    logout() {
      signOut();
    },
    mArrayIncludes(base_array, searchArray) {
      return arrayIncludes(base_array, searchArray);
    }
  }
};
</script>
